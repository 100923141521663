.m-0 {
  margin: 0;
}

.m-1 {
  margin: 0.25rem;
}

.m-2 {
  margin: 0.5rem;
}

.m-3 {
  margin: 1rem;
}

.m-4 {
  margin: 1.5rem;
}

.m-5 {
  margin: 3rem;
}

.m-auto {
  margin: auto;
}

.mx-0 {
  margin-inline-end: 0;
  margin-inline-start: 0;
}

.mx-1 {
  margin-inline-end: 0.25rem;
  margin-inline-start: 0.25rem;
}

.mx-2 {
  margin-inline-end: 0.5rem;
  margin-inline-start: 0.5rem;
}

.mx-3 {
  margin-inline-end: 1rem;
  margin-inline-start: 1rem;
}

.mx-4 {
  margin-inline-end: 1.5rem;
  margin-inline-start: 1.5rem;
}

.mx-5 {
  margin-inline-end: 3rem;
  margin-inline-start: 3rem;
}

.mx-auto {
  margin-inline-end: auto;
  margin-inline-start: auto;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.my-3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-4 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-5 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 1rem;
}

.mt-4 {
  margin-top: 1.5rem;
}

.mt-5 {
  margin-top: 3rem;
}

.mt-auto {
  margin-top: auto;
}

.me-0 {
  margin-inline-end: 0;
}

.me-1 {
  margin-inline-end: 0.25rem;
}

.me-2 {
  margin-inline-end: 0.5rem;
}

.me-3 {
  margin-inline-end: 1rem;
}

.me-4 {
  margin-inline-end: 1.5rem;
}

.me-5 {
  margin-inline-end: 3rem;
}

.me-auto {
  margin-inline-end: auto;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.mb-5 {
  margin-bottom: 3rem;
}

.mb-auto {
  margin-bottom: auto;
}

.ms-0 {
  margin-inline-start: 0;
}

.ms-1 {
  margin-inline-start: 0.25rem;
}

.ms-2 {
  margin-inline-start: 0.5rem;
}

.ms-3 {
  margin-inline-start: 1rem;
}

.ms-4 {
  margin-inline-start: 1.5rem;
}

.ms-5 {
  margin-inline-start: 3rem;
}

.ms-auto {
  margin-inline-start: auto;
}
@include media-breakpoint-max("sm") {
  .m-sm-0 {
    margin: 0;
  }

  .m-sm-1 {
    margin: 0.25rem;
  }

  .m-sm-2 {
    margin: 0.5rem;
  }

  .m-sm-3 {
    margin: 1rem;
  }

  .m-sm-4 {
    margin: 1.5rem;
  }

  .m-sm-5 {
    margin: 3rem;
  }

  .m-sm-auto {
    margin: auto;
  }

  .mx-sm-0 {
    margin-inline-end: 0;
    margin-inline-start: 0;
  }

  .mx-sm-1 {
    margin-inline-end: 0.25rem;
    margin-inline-start: 0.25rem;
  }

  .mx-sm-2 {
    margin-inline-end: 0.5rem;
    margin-inline-start: 0.5rem;
  }

  .mx-sm-3 {
    margin-inline-end: 1rem;
    margin-inline-start: 1rem;
  }

  .mx-sm-4 {
    margin-inline-end: 1.5rem;
    margin-inline-start: 1.5rem;
  }

  .mx-sm-5 {
    margin-inline-end: 3rem;
    margin-inline-start: 3rem;
  }

  .mx-sm-auto {
    margin-inline-end: auto;
    margin-inline-start: auto;
  }

  .my-sm-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .my-sm-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .my-sm-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .my-sm-3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .my-sm-4 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .my-sm-5 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mt-sm-0 {
    margin-top: 0;
  }

  .mt-sm-1 {
    margin-top: 0.25rem;
  }

  .mt-sm-2 {
    margin-top: 0.5rem;
  }

  .mt-sm-3 {
    margin-top: 1rem;
  }

  .mt-sm-4 {
    margin-top: 1.5rem;
  }

  .mt-sm-5 {
    margin-top: 3rem;
  }

  .mt-sm-auto {
    margin-top: auto;
  }

  .me-sm-0 {
    margin-inline-end: 0;
  }

  .me-sm-1 {
    margin-inline-end: 0.25rem;
  }

  .me-sm-2 {
    margin-inline-end: 0.5rem;
  }

  .me-sm-3 {
    margin-inline-end: 1rem;
  }

  .me-sm-4 {
    margin-inline-end: 1.5rem;
  }

  .me-sm-5 {
    margin-inline-end: 3rem;
  }

  .me-sm-auto {
    margin-inline-end: auto;
  }

  .mb-sm-0 {
    margin-bottom: 0;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem;
  }

  .mb-sm-3 {
    margin-bottom: 1rem;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem;
  }

  .mb-sm-5 {
    margin-bottom: 3rem;
  }

  .mb-sm-auto {
    margin-bottom: auto;
  }

  .ms-sm-0 {
    margin-inline-start: 0;
  }

  .ms-sm-1 {
    margin-inline-start: 0.25rem;
  }

  .ms-sm-2 {
    margin-inline-start: 0.5rem;
  }

  .ms-sm-3 {
    margin-inline-start: 1rem;
  }

  .ms-sm-4 {
    margin-inline-start: 1.5rem;
  }

  .ms-sm-5 {
    margin-inline-start: 3rem;
  }

  .ms-sm-auto {
    margin-inline-start: auto;
  }
}
@include media-breakpoint-min("md") {
  .m-md-0 {
    margin: 0;
  }

  .m-md-1 {
    margin: 0.25rem;
  }

  .m-md-2 {
    margin: 0.5rem;
  }

  .m-md-3 {
    margin: 1rem;
  }

  .m-md-4 {
    margin: 1.5rem;
  }

  .m-md-5 {
    margin: 3rem;
  }

  .m-md-auto {
    margin: auto;
  }

  .mx-md-0 {
    margin-inline-end: 0;
    margin-inline-start: 0;
  }

  .mx-md-1 {
    margin-inline-end: 0.25rem;
    margin-inline-start: 0.25rem;
  }

  .mx-md-2 {
    margin-inline-end: 0.5rem;
    margin-inline-start: 0.5rem;
  }

  .mx-md-3 {
    margin-inline-end: 1rem;
    margin-inline-start: 1rem;
  }

  .mx-md-4 {
    margin-inline-end: 1.5rem;
    margin-inline-start: 1.5rem;
  }

  .mx-md-5 {
    margin-inline-end: 3rem;
    margin-inline-start: 3rem;
  }

  .mx-md-auto {
    margin-inline-end: auto;
    margin-inline-start: auto;
  }

  .my-md-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .my-md-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .my-md-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .my-md-3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .my-md-4 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .my-md-5 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .my-md-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mt-md-0 {
    margin-top: 0;
  }

  .mt-md-1 {
    margin-top: 0.25rem;
  }

  .mt-md-2 {
    margin-top: 0.5rem;
  }

  .mt-md-3 {
    margin-top: 1rem;
  }

  .mt-md-4 {
    margin-top: 1.5rem;
  }

  .mt-md-5 {
    margin-top: 3rem;
  }

  .mt-md-auto {
    margin-top: auto;
  }

  .me-md-0 {
    margin-inline-end: 0;
  }

  .me-md-1 {
    margin-inline-end: 0.25rem;
  }

  .me-md-2 {
    margin-inline-end: 0.5rem;
  }

  .me-md-3 {
    margin-inline-end: 1rem;
  }

  .me-md-4 {
    margin-inline-end: 1.5rem;
  }

  .me-md-5 {
    margin-inline-end: 3rem;
  }

  .me-md-auto {
    margin-inline-end: auto;
  }

  .mb-md-0 {
    margin-bottom: 0;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem;
  }

  .mb-md-3 {
    margin-bottom: 1rem;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem;
  }

  .mb-md-5 {
    margin-bottom: 3rem;
  }

  .mb-md-auto {
    margin-bottom: auto;
  }

  .ms-md-0 {
    margin-inline-start: 0;
  }

  .ms-md-1 {
    margin-inline-start: 0.25rem;
  }

  .ms-md-2 {
    margin-inline-start: 0.5rem;
  }

  .ms-md-3 {
    margin-inline-start: 1rem;
  }

  .ms-md-4 {
    margin-inline-start: 1.5rem;
  }

  .ms-md-5 {
    margin-inline-start: 3rem;
  }

  .ms-md-auto {
    margin-inline-start: auto;
  }
}
@include media-breakpoint-min("lg") {
  .m-lg-0 {
    margin: 0;
  }

  .m-lg-1 {
    margin: 0.25rem;
  }

  .m-lg-2 {
    margin: 0.5rem;
  }

  .m-lg-3 {
    margin: 1rem;
  }

  .m-lg-4 {
    margin: 1.5rem;
  }

  .m-lg-5 {
    margin: 3rem;
  }

  .m-lg-auto {
    margin: auto;
  }

  .mx-lg-0 {
    margin-inline-end: 0;
    margin-inline-start: 0;
  }

  .mx-lg-1 {
    margin-inline-end: 0.25rem;
    margin-inline-start: 0.25rem;
  }

  .mx-lg-2 {
    margin-inline-end: 0.5rem;
    margin-inline-start: 0.5rem;
  }

  .mx-lg-3 {
    margin-inline-end: 1rem;
    margin-inline-start: 1rem;
  }

  .mx-lg-4 {
    margin-inline-end: 1.5rem;
    margin-inline-start: 1.5rem;
  }

  .mx-lg-5 {
    margin-inline-end: 3rem;
    margin-inline-start: 3rem;
  }

  .mx-lg-auto {
    margin-inline-end: auto;
    margin-inline-start: auto;
  }

  .my-lg-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .my-lg-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .my-lg-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .my-lg-3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .my-lg-4 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .my-lg-5 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .my-lg-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mt-lg-0 {
    margin-top: 0;
  }

  .mt-lg-1 {
    margin-top: 0.25rem;
  }

  .mt-lg-2 {
    margin-top: 0.5rem;
  }

  .mt-lg-3 {
    margin-top: 1rem;
  }

  .mt-lg-4 {
    margin-top: 1.5rem;
  }

  .mt-lg-5 {
    margin-top: 3rem;
  }

  .mt-lg-auto {
    margin-top: auto;
  }

  .me-lg-0 {
    margin-inline-end: 0;
  }

  .me-lg-1 {
    margin-inline-end: 0.25rem;
  }

  .me-lg-2 {
    margin-inline-end: 0.5rem;
  }

  .me-lg-3 {
    margin-inline-end: 1rem;
  }

  .me-lg-4 {
    margin-inline-end: 1.5rem;
  }

  .me-lg-5 {
    margin-inline-end: 3rem;
  }

  .me-lg-auto {
    margin-inline-end: auto;
  }

  .mb-lg-0 {
    margin-bottom: 0;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem;
  }

  .mb-lg-3 {
    margin-bottom: 1rem;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem;
  }

  .mb-lg-5 {
    margin-bottom: 3rem;
  }

  .mb-lg-auto {
    margin-bottom: auto;
  }

  .ms-lg-0 {
    margin-inline-start: 0;
  }

  .ms-lg-1 {
    margin-inline-start: 0.25rem;
  }

  .ms-lg-2 {
    margin-inline-start: 0.5rem;
  }

  .ms-lg-3 {
    margin-inline-start: 1rem;
  }

  .ms-lg-4 {
    margin-inline-start: 1.5rem;
  }

  .ms-lg-5 {
    margin-inline-start: 3rem;
  }

  .ms-lg-auto {
    margin-inline-start: auto;
  }
}
@include media-breakpoint-min("xl") {
  .m-xl-0 {
    margin: 0;
  }

  .m-xl-1 {
    margin: 0.25rem;
  }

  .m-xl-2 {
    margin: 0.5rem;
  }

  .m-xl-3 {
    margin: 1rem;
  }

  .m-xl-4 {
    margin: 1.5rem;
  }

  .m-xl-5 {
    margin: 3rem;
  }

  .m-xl-auto {
    margin: auto;
  }

  .mx-xl-0 {
    margin-inline-end: 0;
    margin-inline-start: 0;
  }

  .mx-xl-1 {
    margin-inline-end: 0.25rem;
    margin-inline-start: 0.25rem;
  }

  .mx-xl-2 {
    margin-inline-end: 0.5rem;
    margin-inline-start: 0.5rem;
  }

  .mx-xl-3 {
    margin-inline-end: 1rem;
    margin-inline-start: 1rem;
  }

  .mx-xl-4 {
    margin-inline-end: 1.5rem;
    margin-inline-start: 1.5rem;
  }

  .mx-xl-5 {
    margin-inline-end: 3rem;
    margin-inline-start: 3rem;
  }

  .mx-xl-auto {
    margin-inline-end: auto;
    margin-inline-start: auto;
  }

  .my-xl-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .my-xl-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .my-xl-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .my-xl-3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .my-xl-4 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .my-xl-5 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .my-xl-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mt-xl-0 {
    margin-top: 0;
  }

  .mt-xl-1 {
    margin-top: 0.25rem;
  }

  .mt-xl-2 {
    margin-top: 0.5rem;
  }

  .mt-xl-3 {
    margin-top: 1rem;
  }

  .mt-xl-4 {
    margin-top: 1.5rem;
  }

  .mt-xl-5 {
    margin-top: 3rem;
  }

  .mt-xl-auto {
    margin-top: auto;
  }

  .me-xl-0 {
    margin-inline-end: 0;
  }

  .me-xl-1 {
    margin-inline-end: 0.25rem;
  }

  .me-xl-2 {
    margin-inline-end: 0.5rem;
  }

  .me-xl-3 {
    margin-inline-end: 1rem;
  }

  .me-xl-4 {
    margin-inline-end: 1.5rem;
  }

  .me-xl-5 {
    margin-inline-end: 3rem;
  }

  .me-xl-auto {
    margin-inline-end: auto;
  }

  .mb-xl-0 {
    margin-bottom: 0;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem;
  }

  .mb-xl-3 {
    margin-bottom: 1rem;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem;
  }

  .mb-xl-5 {
    margin-bottom: 3rem;
  }

  .mb-xl-auto {
    margin-bottom: auto;
  }

  .ms-xl-0 {
    margin-inline-start: 0;
  }

  .ms-xl-1 {
    margin-inline-start: 0.25rem;
  }

  .ms-xl-2 {
    margin-inline-start: 0.5rem;
  }

  .ms-xl-3 {
    margin-inline-start: 1rem;
  }

  .ms-xl-4 {
    margin-inline-start: 1.5rem;
  }

  .ms-xl-5 {
    margin-inline-start: 3rem;
  }

  .ms-xl-auto {
    margin-inline-start: auto;
  }
}
@include media-breakpoint-min("xxl") {
  .m-xxl-0 {
    margin: 0;
  }

  .m-xxl-1 {
    margin: 0.25rem;
  }

  .m-xxl-2 {
    margin: 0.5rem;
  }

  .m-xxl-3 {
    margin: 1rem;
  }

  .m-xxl-4 {
    margin: 1.5rem;
  }

  .m-xxl-5 {
    margin: 3rem;
  }

  .m-xxl-auto {
    margin: auto;
  }

  .mx-xxl-0 {
    margin-inline-end: 0;
    margin-inline-start: 0;
  }

  .mx-xxl-1 {
    margin-inline-end: 0.25rem;
    margin-inline-start: 0.25rem;
  }

  .mx-xxl-2 {
    margin-inline-end: 0.5rem;
    margin-inline-start: 0.5rem;
  }

  .mx-xxl-3 {
    margin-inline-end: 1rem;
    margin-inline-start: 1rem;
  }

  .mx-xxl-4 {
    margin-inline-end: 1.5rem;
    margin-inline-start: 1.5rem;
  }

  .mx-xxl-5 {
    margin-inline-end: 3rem;
    margin-inline-start: 3rem;
  }

  .mx-xxl-auto {
    margin-inline-end: auto;
    margin-inline-start: auto;
  }

  .my-xxl-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .my-xxl-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .my-xxl-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .my-xxl-3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .my-xxl-4 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .my-xxl-5 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .my-xxl-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mt-xxl-0 {
    margin-top: 0;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem;
  }

  .mt-xxl-3 {
    margin-top: 1rem;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem;
  }

  .mt-xxl-5 {
    margin-top: 3rem;
  }

  .mt-xxl-auto {
    margin-top: auto;
  }

  .me-xxl-0 {
    margin-inline-end: 0;
  }

  .me-xxl-1 {
    margin-inline-end: 0.25rem;
  }

  .me-xxl-2 {
    margin-inline-end: 0.5rem;
  }

  .me-xxl-3 {
    margin-inline-end: 1rem;
  }

  .me-xxl-4 {
    margin-inline-end: 1.5rem;
  }

  .me-xxl-5 {
    margin-inline-end: 3rem;
  }

  .me-xxl-auto {
    margin-inline-end: auto;
  }

  .mb-xxl-0 {
    margin-bottom: 0;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem;
  }

  .mb-xxl-auto {
    margin-bottom: auto;
  }

  .ms-xxl-0 {
    margin-inline-start: 0;
  }

  .ms-xxl-1 {
    margin-inline-start: 0.25rem;
  }

  .ms-xxl-2 {
    margin-inline-start: 0.5rem;
  }

  .ms-xxl-3 {
    margin-inline-start: 1rem;
  }

  .ms-xxl-4 {
    margin-inline-start: 1.5rem;
  }

  .ms-xxl-5 {
    margin-inline-start: 3rem;
  }

  .ms-xxl-auto {
    margin-inline-start: auto;
  }
}
