.bold {
  font-weight: bold;
  font-weight: bold;
}

.regular {
  font-weight: normal;
}

.italic {
  font-style: italic;
}

.text-caps {
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.cursor-pointer {
  cursor: pointer;
}
