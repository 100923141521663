.cdk-overlay-pane {
  &.confirmation-dialog-panel {
    .mat-mdc-dialog-container {
      .mat-mdc-dialog-surface {
        border-radius: 24px !important;
      }
    }
  }

  &.geo-dialog {
    min-width: 100vw !important;
    max-width: 100vw !important;
    height: 100vh !important;

    .mat-mdc-dialog-container {
      .mat-mdc-dialog-surface {
        border-radius: 0 !important;
      }
    }
  }

  &.geo-md-dialog {
    max-width: 90vw !important;
    max-height: 90vh !important;

    .mat-mdc-dialog-container {
      .mat-mdc-dialog-surface {
        border-radius: 24px !important;
      }
    }
  }
}
.cdk-overlay-container {
  z-index: 1000000;
}
