@use "@angular/material" as mat;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@400;500;700&display=swap");

@import "theme/typography";
@import "theme/colors";
@import "layout/variables";

@include mat.core();
$geoking_frontend-primary: mat.define-palette($geo-primary-palette, 700);
$geoking_frontend-accent: mat.define-palette(mat.$cyan-palette, 50, A400);

$geoking_frontend-warn: mat.define-palette(mat.$red-palette);

$geoking_frontend-theme: mat.define-light-theme(
  (
    color: (
      primary: $geoking_frontend-primary,
      accent: $geoking_frontend-accent,
      warn: $geoking_frontend-warn,
    ),
    typography: $geok_typography,
  )
);

@include mat.typography-hierarchy($geok_typography);
@include mat.all-component-themes($geoking_frontend-theme);

@import "helper/reset";
@import "layout/index";

@import "common/common";
@import "common/buttons";
@import "common/inputs";
@import "common/utils";

@import "material/dialog";
@import "material/expansion";
@import "material/input";
@import "material/menu";
@import "material/slide-toggle";
@import "material/slider";

@import "fonts/tahoma/fonts";
@import "fonts/fellix/fonts";

html,
body {
  height: 100%;
  font-family: var(--familyFont);
  background: var(--screenBackgroundColor);
}

@include media-breakpoint-max("md") {
  html,
  body {
    color: $G-Dark;

    &.overflow-hidden {
      overflow: hidden;
    }
  }
}

p {
  padding: 0;
  margin: 0;
}

.material-symbols-outlined {
  font-variation-settings:
    "FILL" 0,
    "wght" 600,
    "GRAD" 0,
    "opsz" 48;
}

html[dir="rtl"] {
  form,
  .header,
  table,
  mat-table,
  button,
  .material-symbols-outlined,
  mat-option,
  mat-snack-bar-container,
  .mat-mdc-dialog-container {
    direction: rtl;
  }
  .leaflet-popup-content-wrapper {
    direction: rtl;
    text-align: right;
  }
}

.tel-form {
  .mat-mdc-form-field {
    margin-bottom: 0;
  }

  .mat-mdc-form-field-icon-suffix {
    display: none;
  }
}

:root {
  //font family
  --familyFont: $font_fellix;
  // buttons
  --buttonBorderRadius: $button-border-radius;
  --iconButtonBorderRadius: $icon-button-border-radius;

  // text, titles, span ..
  --lightThemeColor: $G-Blue2-light-lighting;
  --screenBackgroundColor: $G-Light;
  //TODO: replace with $G-Blue2-light-lighting
  --cardBackgroundColor: #eff7ff;

  --primaryTextColor: $G-Blue1-700;
  --secondaryTextColor: $G-Blue2;
  //navigation
  --navigationGradientOne: $G-nav-bg;
  --navigationGradientTwo: $G-nav-bg;

  --navigationTextColor: $G-Blue1-700;
  --navigationTextActiveColor: $G-Blue2;

  --navigationIconActiveColor: $G-Blue1-700;
  --navigationIconActiveBgColor: $G-Blue2-normal-active;

  // common buttons
  --primaryBgButton: $G-Blue2;
  --primaryBgButtonHover: $G-Blue1-400;
  --primaryTextButton: $G-Light-Normal;

  --secondaryBgButton: $G-Blue3-light-hover;
  --secondaryBgButtonHover: $G-Blue2-light-active;
  --secondaryTextButton: $G-Blue1-700;

  --outlinedBgButtonHover: $G-Blue2-light-lighting;
  --outlinedTextButton: $G-Blue1-700;

  --textBgButtonHover: $G-Blue3-light-hover;
  --textTextButton: $G-Blue1-700;

  --backgroundImage: url("#{$assets}/backgrounds/auth-bg.jpeg");
  --mainLogo: url("#{$assets}/logo.svg");
  --smallLogo: url("#{$assets}/logo-mini.svg");
}
