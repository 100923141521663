
.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.d-block {
  display: block;
}

.d-grid {
  display: grid;
}

.d-inline-grid {
  display: inline-grid;
}

.d-table {
  display: table;
}

.d-table-row {
  display: table-row;
}

.d-table-cell {
  display: table-cell;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.d-none {
  display: none;
}

.flex-fill {
  flex: 1 1 auto;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-stretch {
  align-items: stretch;
}

.align-content-start {
  align-content: flex-start;
}

.align-content-end {
  align-content: flex-end;
}

.align-content-center {
  align-content: center;
}

.align-content-between {
  align-content: space-between;
}

.align-content-around {
  align-content: space-around;
}

.align-content-stretch {
  align-content: stretch;
}

.align-self-auto {
  align-self: auto;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

.align-self-baseline {
  align-self: baseline;
}

.align-self-stretch {
  align-self: stretch;
}

.order-first {
  order: -1;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-last {
  order: 6;
}
.pos-rel {
  position: relative;
}
.pos-abs {
  position: absolute;
}

@include media-breakpoint-max("sm") {
  .d-sm-inline {
    display: inline;
  }

  .d-sm-inline-block {
    display: inline-block;
  }

  .d-sm-block {
    display: block;
  }

  .d-sm-grid {
    display: grid;
  }

  .d-sm-inline-grid {
    display: inline-grid;
  }

  .d-sm-table {
    display: table;
  }

  .d-sm-table-row {
    display: table-row;
  }

  .d-sm-table-cell {
    display: table-cell;
  }

  .d-sm-flex {
    display: flex;
  }

  .d-sm-inline-flex {
    display: inline-flex;
  }

  .d-sm-none {
    display: none;
  }

  .flex-sm-fill {
    flex: 1 1 auto;
  }

  .flex-sm-row {
    flex-direction: row;
  }

  .flex-sm-column {
    flex-direction: column;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-sm-grow-0 {
    flex-grow: 0;
  }

  .flex-sm-grow-1 {
    flex-grow: 1;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1;
  }

  .flex-sm-wrap {
    flex-wrap: wrap;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .justify-content-sm-start {
    justify-content: flex-start;
  }

  .justify-content-sm-end {
    justify-content: flex-end;
  }

  .justify-content-sm-center {
    justify-content: center;
  }

  .justify-content-sm-between {
    justify-content: space-between;
  }

  .justify-content-sm-around {
    justify-content: space-around;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly;
  }

  .align-items-sm-start {
    align-items: flex-start;
  }

  .align-items-sm-end {
    align-items: flex-end;
  }

  .align-items-sm-center {
    align-items: center;
  }

  .align-items-sm-baseline {
    align-items: baseline;
  }

  .align-items-sm-stretch {
    align-items: stretch;
  }

  .align-content-sm-start {
    align-content: flex-start;
  }

  .align-content-sm-end {
    align-content: flex-end;
  }

  .align-content-sm-center {
    align-content: center;
  }

  .align-content-sm-between {
    align-content: space-between;
  }

  .align-content-sm-around {
    align-content: space-around;
  }

  .align-content-sm-stretch {
    align-content: stretch;
  }

  .align-self-sm-auto {
    align-self: auto;
  }

  .align-self-sm-start {
    align-self: flex-start;
  }

  .align-self-sm-end {
    align-self: flex-end;
  }

  .align-self-sm-center {
    align-self: center;
  }

  .align-self-sm-baseline {
    align-self: baseline;
  }

  .align-self-sm-stretch {
    align-self: stretch;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-last {
    order: 6;
  }
}
@include media-breakpoint-min("md") {
  .d-md-inline {
    display: inline;
  }

  .d-md-inline-block {
    display: inline-block;
  }

  .d-md-block {
    display: block;
  }

  .d-md-grid {
    display: grid;
  }

  .d-md-inline-grid {
    display: inline-grid;
  }

  .d-md-table {
    display: table;
  }

  .d-md-table-row {
    display: table-row;
  }

  .d-md-table-cell {
    display: table-cell;
  }

  .d-md-flex {
    display: flex;
  }

  .d-md-inline-flex {
    display: inline-flex;
  }

  .d-md-none {
    display: none;
  }

  .flex-md-fill {
    flex: 1 1 auto;
  }

  .flex-md-row {
    flex-direction: row;
  }

  .flex-md-column {
    flex-direction: column;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-md-grow-0 {
    flex-grow: 0;
  }

  .flex-md-grow-1 {
    flex-grow: 1;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1;
  }

  .flex-md-wrap {
    flex-wrap: wrap;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .justify-content-md-start {
    justify-content: flex-start;
  }

  .justify-content-md-end {
    justify-content: flex-end;
  }

  .justify-content-md-center {
    justify-content: center;
  }

  .justify-content-md-between {
    justify-content: space-between;
  }

  .justify-content-md-around {
    justify-content: space-around;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly;
  }

  .align-items-md-start {
    align-items: flex-start;
  }

  .align-items-md-end {
    align-items: flex-end;
  }

  .align-items-md-center {
    align-items: center;
  }

  .align-items-md-baseline {
    align-items: baseline;
  }

  .align-items-md-stretch {
    align-items: stretch;
  }

  .align-content-md-start {
    align-content: flex-start;
  }

  .align-content-md-end {
    align-content: flex-end;
  }

  .align-content-md-center {
    align-content: center;
  }

  .align-content-md-between {
    align-content: space-between;
  }

  .align-content-md-around {
    align-content: space-around;
  }

  .align-content-md-stretch {
    align-content: stretch;
  }

  .align-self-md-auto {
    align-self: auto;
  }

  .align-self-md-start {
    align-self: flex-start;
  }

  .align-self-md-end {
    align-self: flex-end;
  }

  .align-self-md-center {
    align-self: center;
  }

  .align-self-md-baseline {
    align-self: baseline;
  }

  .align-self-md-stretch {
    align-self: stretch;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-last {
    order: 6;
  }
}
@include media-breakpoint-min("lg") {
  .d-lg-inline {
    display: inline;
  }

  .d-lg-inline-block {
    display: inline-block;
  }

  .d-lg-block {
    display: block;
  }

  .d-lg-grid {
    display: grid;
  }

  .d-lg-inline-grid {
    display: inline-grid;
  }

  .d-lg-table {
    display: table;
  }

  .d-lg-table-row {
    display: table-row;
  }

  .d-lg-table-cell {
    display: table-cell;
  }

  .d-lg-flex {
    display: flex;
  }

  .d-lg-inline-flex {
    display: inline-flex;
  }

  .d-lg-none {
    display: none;
  }

  .flex-lg-fill {
    flex: 1 1 auto;
  }

  .flex-lg-row {
    flex-direction: row;
  }

  .flex-lg-column {
    flex-direction: column;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-lg-grow-0 {
    flex-grow: 0;
  }

  .flex-lg-grow-1 {
    flex-grow: 1;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1;
  }

  .flex-lg-wrap {
    flex-wrap: wrap;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .justify-content-lg-start {
    justify-content: flex-start;
  }

  .justify-content-lg-end {
    justify-content: flex-end;
  }

  .justify-content-lg-center {
    justify-content: center;
  }

  .justify-content-lg-between {
    justify-content: space-between;
  }

  .justify-content-lg-around {
    justify-content: space-around;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly;
  }

  .align-items-lg-start {
    align-items: flex-start;
  }

  .align-items-lg-end {
    align-items: flex-end;
  }

  .align-items-lg-center {
    align-items: center;
  }

  .align-items-lg-baseline {
    align-items: baseline;
  }

  .align-items-lg-stretch {
    align-items: stretch;
  }

  .align-content-lg-start {
    align-content: flex-start;
  }

  .align-content-lg-end {
    align-content: flex-end;
  }

  .align-content-lg-center {
    align-content: center;
  }

  .align-content-lg-between {
    align-content: space-between;
  }

  .align-content-lg-around {
    align-content: space-around;
  }

  .align-content-lg-stretch {
    align-content: stretch;
  }

  .align-self-lg-auto {
    align-self: auto;
  }

  .align-self-lg-start {
    align-self: flex-start;
  }

  .align-self-lg-end {
    align-self: flex-end;
  }

  .align-self-lg-center {
    align-self: center;
  }

  .align-self-lg-baseline {
    align-self: baseline;
  }

  .align-self-lg-stretch {
    align-self: stretch;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-last {
    order: 6;
  }
}
@include media-breakpoint-min("xl") {
  .d-xl-inline {
    display: inline;
  }

  .d-xl-inline-block {
    display: inline-block;
  }

  .d-xl-block {
    display: block;
  }

  .d-xl-grid {
    display: grid;
  }

  .d-xl-inline-grid {
    display: inline-grid;
  }

  .d-xl-table {
    display: table;
  }

  .d-xl-table-row {
    display: table-row;
  }

  .d-xl-table-cell {
    display: table-cell;
  }

  .d-xl-flex {
    display: flex;
  }

  .d-xl-inline-flex {
    display: inline-flex;
  }

  .d-xl-none {
    display: none;
  }

  .flex-xl-fill {
    flex: 1 1 auto;
  }

  .flex-xl-row {
    flex-direction: row;
  }

  .flex-xl-column {
    flex-direction: column;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xl-grow-0 {
    flex-grow: 0;
  }

  .flex-xl-grow-1 {
    flex-grow: 1;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1;
  }

  .flex-xl-wrap {
    flex-wrap: wrap;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .justify-content-xl-start {
    justify-content: flex-start;
  }

  .justify-content-xl-end {
    justify-content: flex-end;
  }

  .justify-content-xl-center {
    justify-content: center;
  }

  .justify-content-xl-between {
    justify-content: space-between;
  }

  .justify-content-xl-around {
    justify-content: space-around;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly;
  }

  .align-items-xl-start {
    align-items: flex-start;
  }

  .align-items-xl-end {
    align-items: flex-end;
  }

  .align-items-xl-center {
    align-items: center;
  }

  .align-items-xl-baseline {
    align-items: baseline;
  }

  .align-items-xl-stretch {
    align-items: stretch;
  }

  .align-content-xl-start {
    align-content: flex-start;
  }

  .align-content-xl-end {
    align-content: flex-end;
  }

  .align-content-xl-center {
    align-content: center;
  }

  .align-content-xl-between {
    align-content: space-between;
  }

  .align-content-xl-around {
    align-content: space-around;
  }

  .align-content-xl-stretch {
    align-content: stretch;
  }

  .align-self-xl-auto {
    align-self: auto;
  }

  .align-self-xl-start {
    align-self: flex-start;
  }

  .align-self-xl-end {
    align-self: flex-end;
  }

  .align-self-xl-center {
    align-self: center;
  }

  .align-self-xl-baseline {
    align-self: baseline;
  }

  .align-self-xl-stretch {
    align-self: stretch;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-last {
    order: 6;
  }
}
@include media-breakpoint-min("xxl") {
  .d-xxl-inline {
    display: inline;
  }

  .d-xxl-inline-block {
    display: inline-block;
  }

  .d-xxl-block {
    display: block;
  }

  .d-xxl-grid {
    display: grid;
  }

  .d-xxl-inline-grid {
    display: inline-grid;
  }

  .d-xxl-table {
    display: table;
  }

  .d-xxl-table-row {
    display: table-row;
  }

  .d-xxl-table-cell {
    display: table-cell;
  }

  .d-xxl-flex {
    display: flex;
  }

  .d-xxl-inline-flex {
    display: inline-flex;
  }

  .d-xxl-none {
    display: none;
  }

  .flex-xxl-fill {
    flex: 1 1 auto;
  }

  .flex-xxl-row {
    flex-direction: row;
  }

  .flex-xxl-column {
    flex-direction: column;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .justify-content-xxl-start {
    justify-content: flex-start;
  }

  .justify-content-xxl-end {
    justify-content: flex-end;
  }

  .justify-content-xxl-center {
    justify-content: center;
  }

  .justify-content-xxl-between {
    justify-content: space-between;
  }

  .justify-content-xxl-around {
    justify-content: space-around;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly;
  }

  .align-items-xxl-start {
    align-items: flex-start;
  }

  .align-items-xxl-end {
    align-items: flex-end;
  }

  .align-items-xxl-center {
    align-items: center;
  }

  .align-items-xxl-baseline {
    align-items: baseline;
  }

  .align-items-xxl-stretch {
    align-items: stretch;
  }

  .align-content-xxl-start {
    align-content: flex-start;
  }

  .align-content-xxl-end {
    align-content: flex-end;
  }

  .align-content-xxl-center {
    align-content: center;
  }

  .align-content-xxl-between {
    align-content: space-between;
  }

  .align-content-xxl-around {
    align-content: space-around;
  }

  .align-content-xxl-stretch {
    align-content: stretch;
  }

  .align-self-xxl-auto {
    align-self: auto;
  }

  .align-self-xxl-start {
    align-self: flex-start;
  }

  .align-self-xxl-end {
    align-self: flex-end;
  }

  .align-self-xxl-center {
    align-self: center;
  }

  .align-self-xxl-baseline {
    align-self: baseline;
  }

  .align-self-xxl-stretch {
    align-self: stretch;
  }

  .order-xxl-first {
    order: -1;
  }

  .order-xxl-0 {
    order: 0;
  }

  .order-xxl-1 {
    order: 1;
  }

  .order-xxl-2 {
    order: 2;
  }

  .order-xxl-3 {
    order: 3;
  }

  .order-xxl-4 {
    order: 4;
  }

  .order-xxl-5 {
    order: 5;
  }

  .order-xxl-last {
    order: 6;
  }
}
@media print {
  .d-print-inline {
    display: inline;
  }

  .d-print-inline-block {
    display: inline-block;
  }

  .d-print-block {
    display: block;
  }

  .d-print-grid {
    display: grid;
  }

  .d-print-inline-grid {
    display: inline-grid;
  }

  .d-print-table {
    display: table;
  }

  .d-print-table-row {
    display: table-row;
  }

  .d-print-table-cell {
    display: table-cell;
  }

  .d-print-flex {
    display: flex;
  }

  .d-print-inline-flex {
    display: inline-flex;
  }

  .d-print-none {
    display: none;
  }
}
