:root {
  --mat-form-field-container-text-line-height: initial;
}

.mat-mdc-form-field-subscript-wrapper .mat-mdc-form-field-error-wrapper,
.mat-mdc-form-field-subscript-wrapper .mat-mdc-form-field-hint-wrapper {
  padding: 0;
}

.mat-mdc-form-field:not(.default-material) .mat-mdc-text-field-wrapper {
  height: 47px;
}

.mat-mdc-form-field:not(.default) {
  width: 100%;
  margin-bottom: 24px;
}

.mat-mdc-form-field {
  font-size: 14px;

  input::placeholder {
    font-size: 14px;
  }
}

.small-field .mat-mdc-form-field-infix {
  min-height: 30px;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.mat-mdc-form-field:not(.default-material)
  .mat-mdc-text-field-wrapper
  .mat-mdc-form-field-flex
  .mat-mdc-floating-label {
  top: 50%;
}

.mat-mdc-form-field:not(.default-material) .mat-mdc-form-field-infix {
  min-height: 47px !important;
  height: 47px !important;
}

/* "outline" appearance */
.mat-mdc-form-field.mat-form-field-appearance-outline:not(.default) {
  /* Invalid */
  &.mat-form-field-invalid {
    /* Floating label */
    .mat-mdc-floating-label {
      mat-label {
        color: rgba(0, 0, 0, 0.6);
        font-size: 14px;
      }
    }
  }

  /* Default style tweaks and enhancements */
  .mat-mdc-text-field-wrapper {
    background-color: white;
    //margin-bottom: 24px;
  }
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined
  .mdc-notched-outline--upgraded
  .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(-28.75px)
    scale(var(--mat-mdc-form-field-floating-label-scale, 0.9));
}

.mat-mdc-select-trigger {
  top: -4px;
  inset-inline-end: -10px;
  inset-inline-start: 0;
}

.mat-mdc-form-field.mat-form-field-appearance-outline:not(
    .default
  ).mat-form-field-disabled
  .mat-mdc-text-field-wrapper {
  background-color: $G-Light-Normal;
}

//hover
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
    .mdc-text-field--focused
  ):hover
  .mdc-notched-outline {
  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing {
    border-color: $G-Light-Normal-hover;
  }
}

//normal
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
    .mat-form-field--invalid
  ) {
  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing {
    border-color: $G-Light-Normal-hover;
  }
}

//focused
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline {
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing,
  .mdc-notched-outline__leading {
    border-color: $G-Light-Normal-active;
  }
}

[dir="rtl"] {
  .mat-mdc-form-field.mat-form-field-appearance-outline {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        .mdc-notched-outline__notch {
          .mat-mdc-floating-label {
            left: initial !important;
            right: 0 !important;
          }
        }
      }
    }
    &.mat-mdc-form-field-has-icon-prefix {
      .mat-mdc-floating-label {
        transform: var(
          --mat-mdc-form-field-label-transform,
          translateY(-50%) translateX(-24px)
        ) !important;
      }
    }
  }
}

.mat-mdc-select {
  line-height: initial !important;
}

.mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
  background: url(#{$assets}/icons/dropdown-arrow.svg);
  margin: 0 4px;
  background-size: 100%;
  width: 20px;
  height: 20px;
  color: transparent;

  svg {
    display: none;
  }
}

.mdc-checkbox
  .mdc-checkbox__native-control:enabled
  ~ .mdc-checkbox__background
  .mdc-checkbox__mixedmark {
  width: 10px;
}
.mdc-checkbox
  .mdc-checkbox__native-control:enabled
  ~ .mdc-checkbox__background
  .mdc-checkbox__checkmark {
  font-size: 11px;
  width: 14px;
  display: flex;
  margin: auto;
  color: white !important;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full,
.mdc-checkbox
  .mdc-checkbox__native-control:enabled:indeterminate
  ~ .mdc-checkbox__background,
.mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background {
  background-color: var(--secondaryTextColor) !important;
  border: var(--secondaryTextColor) !important;
}
.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  border: 1px solid #bfdffe;
  background-color: $G-Blue2-light-lighting !important;
  font-size: 12px;
  line-height: 12px;
  color: var(--primaryTextColor);
}
.mat-mdc-chip.mdc-evolution-chip--with-trailing-action
  .mat-mdc-chip-action-label {
  color: $G-Blue2 !important;
  font-size: 12px;
  line-height: 12px;
}
