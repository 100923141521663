.disabled-input {
  background-color: #f2f2f2;
  color: #ccc;
  cursor: not-allowed !important;
}

.geo-form-field {
  .mat-mdc-text-field-wrapper, .mat-mdc-form-field-flex {
    max-height: 48px;
  }
}

.input-label {
  color: $G-Dark;
  font-size: 16px;
  margin-bottom: 8px;
  display: block;

  &.required::after {
    content: "*";
    color: red;
  }
}
