@import "src/styles/layout/variables";

a.primary-button,
button.primary-button {
  font-size: 16px;
  font-weight: 600;
  background-color: var(--primaryBgButton);
  color: var(--primaryTextButton);
  padding: 10px 16px;
  border-radius: var(--buttonBorderRadius);
  cursor: pointer;
  outline: none;
  border: 1px solid transparent;
  max-width: 100%;
  min-width: 112px;
  height: max-content;
  font-family: var(--familyFont);
  text-decoration: none;

  mat-icon {
    svg {
      path {
        fill: var(--primaryTextButton);
      }
    }
  }

  &:hover {
    background-color: var(--primaryBgButtonHover);
  }

  &:disabled {
    background-color: $G-Light-Normal;
    color: $G-Dark-active;
    cursor: not-allowed;
  }
}

button.base-button {
  font-size: 16px;
  font-weight: 600;
  background-color: transparent;
  color: var(--outlinedTextButton);
  padding: 10px 16px;
  border-radius: var(--buttonBorderRadius);
  cursor: pointer;
  outline: none;
  max-width: 100%;
  min-width: 112px;
  height: max-content;
  font-family: var(--familyFont);
  border: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &:hover {
    background-color: var(--outlinedBgButtonHover);
    color: var(--outlinedTextButton);
  }

  &:disabled {
    background-color: $G-Light-Normal;
    border-color: $G-Light-Normal;
    color: $G-Dark-active;
    cursor: not-allowed;
  }

  &.warn-button {
    background-color: transparent;
    color: $G-Red-dark;
    border-color: $G-Red-dark;
    &:disabled {
      background-color: $G-Light-Normal;
      border-color: $G-Light-Normal;
      color: $G-Dark-active;
      cursor: not-allowed;
    }
  }
}

button.primary-outlined-button {
  font-size: 16px;
  font-weight: 600;
  background-color: transparent;
  color: var(--outlinedTextButton);
  padding: 10px 16px;
  border-radius: var(--buttonBorderRadius);
  cursor: pointer;
  outline: none;
  border: 1px solid $G-Light-Normal-hover;
  max-width: 100%;
  min-width: 112px;
  height: max-content;
  font-family: var(--familyFont);

  &:hover {
    background-color: var(--outlinedBgButtonHover);
    color: var(--outlinedTextButton);
  }

  &:disabled {
    background-color: $G-Light-Normal;
    border-color: $G-Light-Normal;
    color: $G-Dark-active;
    cursor: not-allowed;
  }

  &.warn-button {
    background-color: transparent;
    color: $G-Red-dark;
    border-color: $G-Red-dark;
    &:disabled {
      background-color: $G-Light-Normal;
      border-color: $G-Light-Normal;
      color: $G-Dark-active;
      cursor: not-allowed;
    }
  }
}

button.text-button {
  font-size: 16px;
  font-weight: 600;
  background-color: $transparent;
  color: var(--textTextButton);
  padding: 10px 16px;
  cursor: pointer;
  outline: none;
  border: 1px solid $transparent;
  border-radius: var(--buttonBorderRadius);
  max-width: 100%;
  height: max-content;
  font-family: var(--familyFont);

  &:hover {
    background-color: var(--textBgButtonHover);
    color: var(--secondaryBgButton);
  }

  &:disabled {
    color: $G-Light-Normal-active;
    background-color: $G-Light-Normal;
    cursor: not-allowed;
  }

  &.warn-button {
    color: $G-Red-dark;

    &:hover {
      background-color: $G-Red-light;
    }
  }
}

button.secondary-button {
  font-size: 16px;
  font-weight: 600;
  background-color: var(--secondaryBgButton);
  color: var(--secondaryTextButton);
  padding: 10px 16px;
  border-radius: var(--buttonBorderRadius);
  cursor: pointer;
  outline: none;
  border: 1px solid transparent;
  max-width: 100%;
  height: max-content;
  font-family: var(--familyFont);

  &:hover {
    background-color: var(--secondaryBgButtonHover);
    color: var(--screenBackgroundColor);
  }

  &:disabled {
    background-color: $G-Light-Normal;
    color: $G-Dark-active;
    cursor: not-allowed;
  }
}

a.text-link {
  font-family: var(--familyFont);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: $G-Dark;
  cursor: pointer;
}

button.button-link {
  color: $G-Dark;
  font-family: var(--familyFont);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.15px;
  text-decoration-line: underline;
  display: inline;
  border: 0;
  background: transparent;
  padding: 0;
  width: auto;
  cursor: pointer;
  margin: 0 5px;

  &:disabled {
    color: $G-Dark-active;
    cursor: not-allowed;
  }
}

button.full-button {
  padding: 11px 24px;
  border-radius: 8px;
  color: var(--primaryTextButton);
  background: var(--primaryBgButton);
  font-family: var(--familyFont);
  border: 1px solid transparent;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
  width: 100%;

  &:hover {
    background: var(--primaryBgButtonHover);
  }

  &:disabled {
    background: $G-Light-Normal;
    color: $G-Dark-active;
    cursor: not-allowed;
  }
}

button.icon-button {
  padding: 4px;
  background: transparent;
  border: 0;
}

button.red {
  color: $G-Red-dark;
}
