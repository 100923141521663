mat-slider.mat-mdc-slider {
  direction: ltr;
  --mdc-slider-active-track-height: 4px;
  --mat-mdc-slider-value-indicator-opacity: 1;
  --mdc-slider-label-container-color: var(--secondaryTextColor);
  --mdc-slider-label-label-text-color: #{$G-White};
  --mdc-slider-inactive-track-color: var(--secondaryTextColor);
  --mdc-slider-handle-color: var(--secondaryTextColor);
  --mdc-slider-focus-handle-color: var(--secondaryTextColor);

  &.mat-primary {
    --mdc-slider-focus-handle-color: var(--secondaryTextColor);
    --mat-mdc-slider-hover-ripple-color: #{rgba(var(--secondaryTextColor), 0.05)};
    --mat-mdc-slider-focus-ripple-color: #{rgba(var(--secondaryTextColor), 0.2)};
    --mdc-slider-inactive-track-color: #{$G-Blue2-light-lighting};
    --mdc-slider-active-track-color: var(--secondaryTextColor);
    --mdc-slider-handle-color: var(--secondaryTextColor);
  }

  .md-thumb {
    left: auto;
    right: auto;
  }

  .mdc-slider__value-indicator {
    width: auto;
    height: auto;
    padding: 12px;
  }

  .mdc-slider__value-indicator-text {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.40px;
    word-wrap: break-word;
    white-space: nowrap;
  }

  .mdc-slider__track--inactive {
    opacity: 1;
  }
}

[dir="rtl"] {
  mat-slider.mat-mdc-slider {
    transform: rotate(180deg);

    .mdc-slider__value-indicator-container {
      top: 44px;
      bottom: unset;
      transform: var(--slider-value-indicator-container-transform, translateX(-50%)) rotate(180deg);
    }

    .md-thumb-container {
      left: 0;
      right: auto;
    }
  }
}
